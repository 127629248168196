<template>
	<div id="sitelogin">
		<div class="loginbox" v-loading="loading">
			<div class="titlebox">				
				<span>{{shop_name||''}}</span>
				<span>商家登录后台</span>			
			</div>
			
			<div class="inputbox" style="margin-bottom: 40px;">
				<div class="label">登录账号</div>
				<el-input type="text" style="input" v-model="user_name" placeholder="请输入账号"></el-input>
			</div>
			
			<div class="inputbox">
				<div class="label">登录密码</div>
				<el-input type="password" style="input" v-model="password" placeholder="请输入密码"></el-input>
			</div>
			
			<el-button class="loginbtn" type="primary" @click='login'>登录</el-button>
		</div>
	</div>
</template>

<script>
import { eltips,getUrlKey } from '@/util/util.js';
import {resetRouter} from '@/router/index.js'
import store from '@/store/index.js'
export default {
	name: 'sitelogin',
	data() {
		return {
			shop_name:'',
			user_name:'',//用户名
			password:'',//密码
			guard_name:'',//应用名称
			shop_id:'',
			loading:false,
			clear:''
		};
	},
	created() {
		if(getUrlKey('name')){
			this.shop_name = getUrlKey('name')
		}
		
		if(getUrlKey('shop_id')){
			this.shop_id = getUrlKey('shop_id')
		}
		
		if(getUrlKey('guard_name')){
			this.guard_name = getUrlKey('guard_name')
		}
		if(getUrlKey('clear')){
			this.clear = getUrlKey('clear')
		}
	},
	methods:{
		//登录
		login(){
			if(!this.user_name){
				eltips('请输入用户名','error')
				return
			}
			if(!this.password){
				eltips('请输入密码','error')
				return
			}
			
			let data = {
				shop_id:this.shop_id,
				user_name:this.user_name,
				password:this.password
			}
			if(this.guard_name){
				data.guard_name = this.guard_name
				sessionStorage.setItem("guardName",this.guard_name)
			}
			this.loading = true
			this.axios.post('/api/ecshop/admin_user/login',data).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('token',res.data.access_token)
					sessionStorage.removeItem('role_id')
					sessionStorage.removeItem('pluginroute')
					sessionStorage.removeItem('pluginid')
					this.getuserinfo()
				}else{
					this.loading = false
					eltips(res.msg,'error')
				}
			})
		},
		//获取个人信息
		getuserinfo(){
			this.axios.get('/api/me',{params:{
				guard_name:this.guard_name
			}}).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('userinfo',JSON.stringify(res.data))
					this.getmeuns()
				}else{
					this.loading = false
					eltips(res.msg,'error')
				}
			})
		},
		//获取权限菜单
		getmeuns(){
			this.axios.get('/api/get_menu').then(res=>{
				resetRouter()
				this.$store.commit('routestatus', 0)
				sessionStorage.removeItem('routestext')
				sessionStorage.removeItem('routes')
				let routes=JSON.parse(JSON.stringify(res.data))
				let pathUrl = '/index'
				// 如果是简洁版
				if(this.clear){
					console.log(routes)
					let arr = []
					routes.forEach(item => {
						if(item.id==317||item.id==319){
							this.arrary=[]
							const res = new Map();
							item._child=item._child.filter((a) => a.id!=1261 && a.id!=1294)
							arr.push(item)
						}
					});
					routes = arr
					pathUrl ='/shopping/vip/list'
					sessionStorage.setItem("clear",1);
				}
				// sessionStorage.setItem('routes',JSON.stringify(res.data))
				sessionStorage.setItem('routes',JSON.stringify(routes))
				this.$router.replace({path: pathUrl})
				this.loading = false
				let path
				// if(res.data[0].path){
				if(routes[0].path){
					path = res.data[0].path
				}else{
					// let child = res.data[0]._child
					let child = routes[0]._child
					for(let i=0;i<child.length;i++){
						if(child[i].path){
							path = child[i].path
							break 
						}
					}
				}
				this.$router.replace({path: path})
			})
		},
	}
};
</script>


<style>
	#sitelogin .el-input__inner{
		border: none !important;
	}
	
	#sitelogin .el-button{
		padding: 18px 20px !important;
		font-size: 16px;
		font-weight: bold;
	}
</style>
<style scoped lang="less">
#sitelogin{
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: url(../../assets/images/login_bg.png);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 4px 4px 2px rgba(0,0,0,.1);
	.loginbox{
		width: 380px;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 30px;
	}
	.titlebox{
		font-size: 20px;
		color: #1E92FF;
		margin-bottom: 40px;
		font-weight: bold;
	}
	.inputbox{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #DEDEDE;
		padding-bottom: 6px;
		.label{
			width: 80px;
		}
		.input{
			
		}
	}
	.loginbtn{
		margin-top: 160px;
		width: 100%;
	}
}
</style>
